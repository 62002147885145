import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const FirstHalfSetting = React.lazy(() => import('./TourSettingPages/FirstHalfSetting'));
const SecondHalfSetting = React.lazy(() => import('./TourSettingPages/SecondHalfSetting'));
const LoadCapacitySetting = React.lazy(() => import('./TourSettingPages/LoadCapacitySetting'));
const ListMaximumStop = React.lazy(() => import('./TourStopPages/ListMaximumStop'));
const AddMaximumStop = React.lazy(() => import('./TourStopPages/AddMaximumStop'));
const EditMaximumStop = React.lazy(() => import('./TourStopPages/EditMaximumStop'));
const OperationalTimeSetting = React.lazy(() =>
  import('./TourSettingPages/OperationalTimeSetting')
);
const RealTimeSetting = React.lazy(() => import('./TourSettingPages/RealTimeSetting'));
const TourList = React.lazy(() => import('./Tours/TourList'));
const TourWarehouseList = React.lazy(() => import('./Tours/TourWarehouseList'));

const TourRoutes = ({ t }) => {
  return (
    <Routes>
      <Route exact path="/first_half" element={<FirstHalfSetting t={t} />} />
      <Route path="/second_half" element={<SecondHalfSetting t={t} />} />
      <Route path="/load_setting" element={<LoadCapacitySetting t={t} />} />
      <Route path="/maximum_stop" element={<ListMaximumStop t={t} />} />
      <Route path="/maximum_stop/add" element={<AddMaximumStop t={t} />} />
      <Route path="/maximum_stop/edit/:maximum_stop_id" element={<EditMaximumStop t={t} />} />
      <Route path="/operational_time" element={<OperationalTimeSetting t={t} />} />
      <Route path="/real_time_setting" element={<RealTimeSetting t={t} />} />
      <Route path="/list" element={<TourList t={t} />} />
      <Route path="/warehouse" element={<TourWarehouseList t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
TourRoutes.propTypes = {
  t: PropTypes.func
};
export default TourRoutes;
