import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ListDriverPage, EditDriverPage, AddDriverPage, ViewDriverPage } from './index';
const DriverRoutes = ({ t }) => {
  return (
    <Routes>
      <Route exact path="/list/:transporter_id" element={<ListDriverPage t={t} />} />
      <Route exact path="/add/:transporter_id" element={<AddDriverPage t={t} />} />
      <Route exact path="/edit/:transporter_id/:driver_id" element={<EditDriverPage t={t} />} />
      <Route exact path="/view/:transporter_id/:driver_id" element={<ViewDriverPage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
DriverRoutes.propTypes = {
  t: PropTypes.func
};
export default DriverRoutes;
