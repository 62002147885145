// import libs
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { useGetSettingData } from '@hooks';
import {
  ForgetPasswordPage,
  LoginPage,
  OtpVerificationPage,
  ResetPasswordPage
} from '../pages/Auth';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

import { ChangePasswordPage } from '../pages/Accounts/ChangePassword/ChangePasswordPage';
import { EditProfilePage } from '../pages/Accounts/EditProfile/EditProfilePage';
import { SetPasswordPage } from '../pages/Auth/SetPassword/SetPasswordPage';
import DashboardPage from '../pages/Dashboard/NewDashboardPage';
import { addSetting, settingData } from '../store/features/settingSlice';

import PageNotFound from '@common/components/PageNotFound';
import ContainerRoutes from '../pages/ContainerDetails/ContainerRoutes';
import ContainerHistoryRoutes from '../pages/ContainerHistory/ContainerHistoryRoutes';
import DeliveryPointUserRoute from '../pages/DeliveryPointUsers/DeliveryPointUserRoutes';
import DeliverySessionRoutes from '../pages/DeliverySession/DeliverySessionRoutes';
import FactoryRoutes from '../pages/FactoryPages/FactoryRoutes';
import LoadTransportTruckRoutes from '../pages/LoadTransportTruck/LoadTransportTruckRoutes';
import AbortRoutes from '../pages/Masters/Abort/AbortRoutes';
import AgentRoutes from '../pages/Masters/Agent/AgentRoutes';
import AreaRoutes from '../pages/Masters/Areas/AreaRoutes';
import CmsRoutes from '../pages/Masters/Cms/CmsRoutes';
import CouponRoutes from '../pages/Masters/Coupon/CouponRoutes';
import EmailTemplateRoutes from '../pages/Masters/EmailTemplate/EmailTemplateRoutes';
import ProducerTypeRoutes from '../pages/Masters/ProducerType/ProducerTypeRoutes';
import TerritoryRoutes from '../pages/Masters/Territory/TerritoryRoutes';
import TranslationRoutes from '../pages/Masters/Translations/TranslationRoutes';
import UserRoutes from '../pages/Masters/Users/UserRoutes';
import WasteCodeRoutes from '../pages/Masters/WasteCode/WasteCodeRoutes';
import NotificationRoutes from '../pages/Notifications/NotificationRoutes';
import OrderRoutes from '../pages/Orders/OrderRoutes';
import ProducerRoutes from '../pages/ProducerPages/ProducerRoutes';
import ScanPointRoutes from '../pages/ScanPoints/ScanPointRoutes';
import StocksRoutes from '../pages/Stocks/StocksRoutes';
import StoreSessionRoutes from '../pages/StoreSession/StoreSessionRoutes';
import AvailableCollectRoutes from '../pages/Tour/AvailableCollect/AvailableCollectRoutes';
import TourMasterRoutes from '../pages/Tour/TourMaster/TourMasterRoute';
import TourRoutes from '../pages/Tour/TourRoutes';
import TransporterRoutes from '../pages/TransporterPages/TransporterRoutes';
import UnloadCollectTruckRoutes from '../pages/UnloadCollectTruck/UnloadCollectTruckRoutes';
import UnloadTransportTruckRoutes from '../pages/UnloadTransportTruck/UnloadTransportTruckRoutes';
import WarehouseRoutes from '../pages/WarehousePages/WarehouseRoutes';

import InsinerasiRoutes from '../pages/Insinerasi/InsinerasiRoutes';
import PendingIncinerationRoutes from '../pages/PendingIncineration/PendingIncinerationRoutes';

import CompletedIncinerationRoutes from '../pages/CompletedIncineration/CompletedIncinerationRoutes';
import AgentPayoutRoutes from '../pages/Finance/Agent/AgentPayoutRoutes';
import FactoryPayoutRoutes from '../pages/Finance/Factory/FactoryPayoutRoutes';
import TransporterPayoutRoutes from '../pages/Finance/Transporter/TransporterPayoutRoutes';

import InvoiceRoutes from '../pages/Finance/Invoice/InvoiceRoutes';

import PrintStickerRoutes from '../pages/PrintSticker/PrintStickerRoutes';

import ReferralRoutes from '../pages/Referral/ReferralRoutes';

import CourierRoutes from '../pages/Courier/CourierRoutes';

import MultiScreenPage from '../pages/Dashboard/MultiScreenPage';

import ReportRoutes from '../pages/Reports/ReportRoutes';

import ContainerReusableHistoryRoutes from '../pages/ContainerReusableHistory/ContainerReusableHistoryRoutes';

import ContainerRecycleHistoryRoutes from '../pages/ContainerRecycleHistory/ContainerRecycleHistoryRoutes';

import ManifestsRoutes from '../pages/Manifests/ManifestsRoutes';

import PostpaidInvoiceRoutes from '../pages/PostpaidInvoice/PostpaidInvoiceRoute';

import PartnerInvoiceRoutes from '../pages/PartnerInvoice/PartnerInvoiceRoute';

import ContainerSerialWeightRoutes from '../pages/ContainerSerialWeight/ContainerSerialWeightRoutes';

const ScheduleRoute = React.lazy(() => import('../pages/Schedule/ScheduleRoute'));
const MessageRoute = React.lazy(() => import('../pages/Messages/MessagesRoute'));
const MouRoute = React.lazy(() => import('../pages/ProducerPages/Mou/MouRoute'));

const PrepaidInvoice = React.lazy(() => import('../pages/PrepaidInvoice/PrepaidInvoice'));

const SettingRoute = React.lazy(() => import('../pages/Setting/SettingRoute'));

const PagesRoutes = ({ t }) => {
  const dispatch = useDispatch();
  useGetSettingData(({ data: setting }) => {
    const dataStore = {
      dashboard_logo: setting.common_header_logo,
      front_logo: setting.common_login_logo,
      email_logo: setting?.common_email_header_logo,
      favicon_icon: setting?.common_favicon_icon,
      seo_title: setting.seo_title,
      seo_description: setting.seo_description,
      setting_get: false
    };
    dispatch(addSetting(dataStore));
  });

  const getSettingData = useSelector(settingData);
  return (
    <Router basename={'/admin/'}>
      <Helmet>
        <title>Clean Medic - Admin</title>
        <meta name="title" content="Clean Medic" />
        <meta name="description" content="Clean Medic" />
        <meta name="og:title" content="Clean Medic" />
        <meta name="og:description" content="Clean Medic" />
        <link rel="icon" type="image/x-icon" href={`${getSettingData.favicon_icon}`} />
      </Helmet>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route exact path="/" element={<LoginPage t={t} />} />
          <Route exact path="/login" element={<LoginPage t={t} />} />
          <Route exact path="/forget-password" element={<ForgetPasswordPage t={t} />} />
          <Route exact path="/otp-verification" element={<OtpVerificationPage t={t} />} />
          <Route exact path="/reset-password" element={<ResetPasswordPage t={t} />} />
          <Route exact path="/set-password/:user_id" element={<SetPasswordPage t={t} />} />
        </Route>

        <Route element={<PrivateRoute />}>
          <Route exact path="/dashboard" element={<DashboardPage t={t} />} />
          <Route basename={'/dashboard/'}>
            <Route exact path="/dashboard/multiscreen" element={<MultiScreenPage t={t} />} />
          </Route>

          <Route exact path="/edit-profile" element={<EditProfilePage t={t} />} />
          <Route exact path="/change-password" element={<ChangePasswordPage t={t} />} />

          {/* Factory Routes */}
          <Route path="/warehouse/*" element={<WarehouseRoutes t={t} />} />

          {/* Factory Routes */}
          <Route path="/factory/*" element={<FactoryRoutes t={t} />} />
          {/* Transporter Routes */}
          <Route path="/transporter/*" element={<TransporterRoutes t={t} />} />

          {/* Producer Routes */}
          <Route path="/producer/*" element={<ProducerRoutes t={t} />} />
          {/* <Route basename={'/setting/'}>
            <Route exact path="/setting/general" element={<GeneralSetting t={t} />} />
          </Route> */}

          <Route path="/schedule/*" element={<ScheduleRoute t={t} />} />

          {/* <Route exact path="/setting/download-link" element={<DownLoadLinkSettingPage t={t} />} /> */}

          {/* Own Warehouse Transporter Routes */}
          {/* <Route
            path="/setting/own-warehouse-transporter/*"
            element={<OwnWarehouseRoutes t={t} />}
          /> */}

          {/* Translations Routes */}
          <Route path="/translations/*" element={<TranslationRoutes t={t} />} />

          {/* Territories Routes */}
          <Route path="/territories/*" element={<TerritoryRoutes t={t} />} />

          {/* Area Routes */}
          <Route path="/areas/*" element={<AreaRoutes t={t} />} />

          {/* Cms Routes */}
          <Route path="/cms/*" element={<CmsRoutes t={t} />} />

          {/* Master Email Template Routes */}
          <Route path="/email-template/*" element={<EmailTemplateRoutes t={t} />} />

          {/* Producer Type Routes */}
          <Route path="/producertype/*" element={<ProducerTypeRoutes t={t} />} />

          {/* WasteCode Routes */}
          <Route path="/wastecode/*" element={<WasteCodeRoutes t={t} />} />

          {/* Users Routes */}
          <Route path="/users/*" element={<UserRoutes t={t} />} />

          {/* Delivery Point User Routes */}
          <Route
            path="/:type/:alliance_portal_id/delivery/*"
            element={<DeliveryPointUserRoute t={t} />}
          />
          {/* Scan Point User Routes */}
          <Route path="/:type/:alliance_portal_id/scan/*" element={<ScanPointRoutes t={t} />} />

          {/* Container Routes */}
          <Route path="/container-master/*" element={<ContainerRoutes t={t} />} />

          <Route path="/container-history/*" element={<ContainerHistoryRoutes t={t} />} />

          {/* Stocks Routes */}
          <Route path="/stocks-history/*" element={<StocksRoutes t={t} />} />

          {/* Delivery Charges Routes */}
          {/* <Route path="/setting/delivery-charges/*" element={<DeliveryChargesRoutes t={t} />} /> */}
          {/* Agents Routes */}
          <Route path="/agent/*" element={<AgentRoutes t={t} />} />

          {/* Coupon Routes */}
          <Route path="/coupon/*" element={<CouponRoutes t={t} />} />

          {/* Order Routes */}
          <Route path="/orders/*" element={<OrderRoutes t={t} />} />

          {/* Notification Routes */}
          <Route path="/notification/*" element={<NotificationRoutes t={t} />} />

          {/* Tour Master Routes */}
          <Route path="/tour/*" element={<TourRoutes t={t} />} />

          {/* Abort Master Routes */}
          <Route path="/abort/*" element={<AbortRoutes t={t} />} />

          {/* Available Collect Routes */}
          <Route path="/tour/available_collect/*" element={<AvailableCollectRoutes t={t} />} />

          {/* Tour Collect Routes */}
          <Route path="/tours/*" element={<TourMasterRoutes t={t} />} />

          {/* Unload Collect Truck Routes */}
          <Route
            path="/tours/unload-collect-truck/*"
            element={<UnloadCollectTruckRoutes t={t} />}
          />

          {/* Store Session Routes */}
          <Route path="/tour/store-session/*" element={<StoreSessionRoutes t={t} />} />

          {/*Load Transport Truck Routes */}
          <Route path="/tour/load-transport-truck/*" element={<LoadTransportTruckRoutes t={t} />} />

          {/*Pending Incineration Routes */}
          <Route path="/incineration/*" element={<InsinerasiRoutes t={t} />} />
          {/*Pending Incineration Routes */}
          <Route
            path="/tour/pending-incineration/*"
            element={<PendingIncinerationRoutes t={t} />}
          />
          {/*Unload Transport Truck Routes */}
          <Route
            path="/tour/transport-truck-unload/*"
            element={<UnloadTransportTruckRoutes t={t} />}
          />
          {/*Complete Incineration Routes */}
          <Route
            path="/tour/completed-incineration/*"
            element={<CompletedIncinerationRoutes t={t} />}
          />

          {/* Delivery Session Master Routes */}
          <Route path="/delivery-session/*" element={<DeliverySessionRoutes t={t} />} />

          {/*Complete Incineration Routes */}
          <Route path="/finance/trasnporter/*" element={<TransporterPayoutRoutes t={t} />} />

          <Route path="/finance/factory/*" element={<FactoryPayoutRoutes t={t} />} />

          <Route path="/finance/agent/*" element={<AgentPayoutRoutes t={t} />} />

          {/*Invoice Routes */}
          <Route path="/finance/invoice/*" element={<InvoiceRoutes t={t} />} />

          <Route path="/finance/referral/*" element={<ReferralRoutes t={t} />} />

          {/*Print Sticker Routes*/}
          <Route path="/print-sticker/*" element={<PrintStickerRoutes t={t} />} />

          <Route path="/courier/*" element={<CourierRoutes t={t} />} />

          <Route path="/report/*" element={<ReportRoutes t={t} />} />

          <Route path="/reusable-history/*" element={<ContainerReusableHistoryRoutes t={t} />} />

          <Route path="/recycle-history/*" element={<ContainerRecycleHistoryRoutes t={t} />} />

          <Route path="/manifests/*" element={<ManifestsRoutes t={t} />} />

          <Route path="/setting/*" element={<SettingRoute t={t} />} />

          {/* <Route path="/setting/working-hour" element={<WorkingHour t={t} />} />

          <Route path="/setting/trials" element={<TrialSetting />} /> */}

          <Route path="/postpaid-invoices/*" element={<PostpaidInvoiceRoutes t={t} />} />

          <Route path="/partner-invoices/*" element={<PartnerInvoiceRoutes t={t} />} />

          <Route path="/messages/*" element={<MessageRoute t={t} />} />

          <Route path="/prepaid-invoices" element={<PrepaidInvoice t={t} />} />

          <Route
            path="/container-serial-weight/*"
            element={<ContainerSerialWeightRoutes t={t} />}
          />

          <Route path="/mou/*" element={<MouRoute t={t} />} />
        </Route>

        <Route>
          <Route path="*" element={<PageNotFound t={t} from="admin" />} />
        </Route>
      </Routes>
    </Router>
  );
};
PagesRoutes.propTypes = {
  t: PropTypes.func
};
export default PagesRoutes;
