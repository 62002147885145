import PropTypes from 'prop-types';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AddProducerPage } from './AddProducerPage';
import { EditProducerPage } from './EditProducerPage';
import { ListProducerPage } from './ListProducerPage';
import ProducerContainerHistory from './ProducerContainerHistory';
import ProducerContainerStock from './ProducerContainerStock';
import { ViewProducerPage } from './ViewProducerPage';

const EditProducerPricingTier = React.lazy(() => import('./EditProducerPricingTier'));
const ListTrialPage = React.lazy(() => import('./Trial/ListTrial'));
const DetailTrialPage = React.lazy(() => import('./Trial/DetailTrial'));
const ListAddressPage = React.lazy(() => import('./Address/ListAddress'));

const ProducerRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index path="/list/" element={<ListProducerPage t={t} />} />
      <Route path="/add" element={<AddProducerPage t={t} />} />
      <Route path="/edit-pricing-tier/:producer_id" element={<EditProducerPricingTier t={t} />} />
      <Route path="/edit/:producer_id" exact element={<EditProducerPage t={t} />} />
      <Route exact path="/view/:producer_id" element={<ViewProducerPage t={t} />} />
      <Route
        exact
        path="/view/:producer_id/containers"
        element={<ProducerContainerHistory t={t} />}
      />
      <Route exact path="/view/:producer_id/stocks" element={<ProducerContainerStock t={t} />} />
      <Route path="trial" element={<ListTrialPage />} />
      <Route path="trial/:id" element={<DetailTrialPage />} />
      <Route path="edit/:id/addresses" element={<ListAddressPage />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
ProducerRoutes.propTypes = {
  t: PropTypes.func
};
export default ProducerRoutes;
